@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');

body {
  font-family: 'Noto Sans KR', sans-serif;
}

#timeline {
  margin-top: 80px; /* Increase margin-top to create space from header */
  padding: 0 20px;
  position: relative;
}

.main-title {
     font-size: 2em;
     font-weight: 700;
     text-align: center;
     margin-bottom: 50px;
     margin-top: 20px;
}

.timeline-year {
  margin-bottom: 30px;
  position: relative;
}

.timeline-year-header h2 {
  font-size: 1.5em;
  font-weight: 700;
  margin: 0;
  position: relative;
  left: -60px;
}

.timeline-events {
  margin-left: 40px;
  border-left: 2px solid #ddd;
  padding-left: 20px;
  position: relative;
}

.timeline-event {
  position: relative;
  padding: 10px 0;
}

.timeline-event-content {
  display: flex;
  align-items: center;
}

.timeline-event-month {
  font-size: 1em;
  font-weight: 700;
  color: #555;
  margin-right: 10px;
  width: 30px;
}

.timeline-event-description {
  font-size: 1em;
  font-weight: 400;
  color: #333;
}

.timeline-event::before {
  content: '';
  position: absolute;
  left: -12px;
  width: 8px;
  height: 8px;
  background: #ddd;
  border: 2px solid #ddd;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}
