@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');

body {
  font-family: 'Noto Sans KR', sans-serif;
}

.ceo-greeting-container {
  margin-top: 80px; /* Space from header */
  padding: 20px;
  background-color: #f9f9f9; /* Light background */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.ceo-greeting-title {
  font-size: 2em;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}

.ceo-greeting-text {
  font-size: 1.2em;
  line-height: 1.6;
  text-align: center;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
  margin-bottom: 20px;
}
