/* Intro.css */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@200;500&display=swap');

#intro {
     position: relative;
     width: 100%;
     height: 100vh; /* Viewport height */
     display: flex;
     align-items: center;
     justify-content: center;
     overflow: hidden;
     font-family: 'Noto Sans KR', sans-serif; /* 기본 폰트 적용 */
     font-size: 20px; /* 기본 폰트 크기 조정 */
 }
 
 .intro__inner {
     position: relative;
     width: 100%;
     height: 100%;
 }
 
 .intro__title {
     position: absolute;
     top: 20px;
     left: 20px;
     font-size: 2.5rem;
     color: white;
     z-index: 10;
 }
 
 .intro__image-container {
     position: relative;
     width: 100%;
     height: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
 }
 
 .intro__blur-image {
     position: absolute;
     width: 100%;
     height: 100%;
     object-fit: cover;
     filter: blur(10px); /* Blur 처리 */
 }
 
 .intro__text-overlay {
     position: relative;
     z-index: 10;
     color: white; /* 텍스트를 흰색으로 설정 */
     font-size: 1.5rem;
     text-align: center;
     padding: 20px;
     background-color: rgba(0, 0, 0, 0.4); /* 배경에 반투명 검은색 추가 */
     border-radius: 10px;
 }
 