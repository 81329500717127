@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');

body {
     font-family: 'Noto Sans KR', sans-serif;
   }
   
   #mes {
     text-align: left;
     margin-top: 20px;
   }
   
   .product-name {
     font-size: 3em;
     font-weight: 700;
     margin-bottom: 20px;
   }
   
   .product-title {
     font-size: 1.5em;
     font-weight: 500;
     margin-top: 20px;
   }
   
   .product-content {
     font-size: 1.2em;
     font-weight: 300;
     margin: 5px 5px 20px 20px;
     line-height: 1.6;
   }
   
   .product-images img {
     max-width: 100%;
     height: auto;
   }
   