/* Footer.css */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@200;500&display=swap');

/* Footer.css */
#footer {
     background-color: #2c2c2c;
     color: rgba(255, 255, 255, 0.8);
     padding: 20px 0;
     font-size: 14px;
     font-family: 'Noto Sans KR', sans-serif; /* 기본 폰트 적용 */
     font-size: 14; /* 기본 폰트 크기 조정 */
     margin-top: 220px; /* Add top margin to the footer */
 }
 
 .footer__inner {
     max-width: 1200px;
     margin: 0 auto;
     padding: 0 20px;
     display: flex;
     flex-direction: column;
     align-items: flex-start;
 }
 
 .footer__content {
     margin-bottom: 20px;
 }
 
 .footer__company-name {
     font-weight: bold;
     font-size: 16px;
     margin-top: 60px;
     margin-bottom: 20px;
     color: rgb(255, 255, 255, 0.7);
 }

 .footer__address {
     margin-bottom: 0px;
     color: rgb(255, 255, 255, 0.4);
 }
 
 .footer__contact {
     margin: 0px;
 }
 
 .footer__links {
     display: flex;
     flex-wrap: wrap;
     gap: 20px;
 }
 
 .footer__links div {
     flex: 1;
     min-width: 200px;
 }
 
 .footer__links p {
     font-weight: bold;
     margin-bottom: 10px;
 }
 
 .footer__links ul {
     list-style: none;
     padding: 0;
     margin: 0;
 }
 
 .footer__links ul li {
     margin-bottom: 5px;
 }
 
 .footer__links ul li a {
     color: #fff;
     text-decoration: none;
     transition: color 0.3s;
 }
 
 .footer__links ul li a:hover {
     color: #ffab00;
 }
 